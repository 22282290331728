export const asesores = [
    {
      nombre: "Roque Lucero",
      sucursal: "Villa Mercedes, San Luis",
      whatsapp: "5492664328116",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/roquelucero.png",
      correo: "roquelucero@terraloteos.com",
    },
    {
      nombre: "Eugenia Sosa",
      sucursal: "San Luis Capital, San Luis",
      whatsapp: "5492664771323",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/eugeniasosa.png",
      correo: "eugeniasosa@terraloteos.com",
    },
    {
      nombre: "Oscar Miranda",
      sucursal: "San Luis Capital, San Luis",
      whatsapp: "5492664576206",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/oscarmiranda.png",
      correo: "oscarmiranda@terraloteos.com",
    },
    {
      nombre: "Gerardo Nahas",
      sucursal: "Río Cuarto, Córdoba",
      whatsapp: "5493584379720",
      puesto: "Jefe de Oficina",
      imagen: "https://terraloteos.com/img/asesores/gerardonahas.png",
      correo: "gerardonahas@terraloteos.com",
    },
    {
      nombre: "Gonzalo Alba",
      sucursal: "Río cuarto, Córdoba",
      whatsapp: "5493585147095",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/gonzaloalba.png",
      correo: "gonzaloalba@terraloteos.com",
    },
    {
      nombre: "Matias Zarate",
      sucursal: "Río Cuarto, Córdoba.",
      whatsapp: "5493584902452",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/matiaszarate.png",
      correo: "matiaszarate@terraloteos.com",
    },
    {
      nombre: "Aldo Zabala",
      sucursal: "Villa de Merlo",
      whatsapp: "5492664013254",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/aldozabala.png",
      correo: "aldozabala@terraloteos.com",
    },
    {
      nombre: "Ignacio Cordero",
      sucursal: "Rio Cuarto",
      whatsapp: "3585488671",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/ignaciocordero.png",
      correo: "nachocordero31@gmail.com",
    },
    {
      nombre: "Leandro Contreras",
      sucursal: "Villa de Merlo",
      whatsapp: "2664581548",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/leandrocontreras.png",
      correo: "leoanto21016@gmail.com",
    },
    {
      nombre: "Natalia Gonzalez",
      sucursal: "Villa de Merlo",
      whatsapp: "3513002763",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/nataliagonzalez.png",
      correo: "nataliasgonzalez26@gmail.com",
    },
    {
      nombre: "Paula Moreno",
      sucursal: "Rio Cuarto",
      whatsapp: "3382504774",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/paulamoreno.png",
      correo: "paulli.moreno003@gmail.com",
    },
    {
      nombre: "Damian Anselmo",
      sucursal: "San Luis",
      whatsapp: "1164837607",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/damiananselmo.png",
      correo: "damian.anselmo14@gmail.com",
    },
    {
      nombre: "Yesica Bustos",
      sucursal: "Villa de Merlo",
      whatsapp: "2613869058",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/yesicabustos.png",
      correo: "yesicabustosvan@gmail.com",
    },
    {
      nombre: "Norman Paredez",
      sucursal: "Rio Cuarto",
      whatsapp: "3585717822",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/normanparedez.png",
      correo: "normanparedez@gmail.com",
    },
    {
      nombre: "Liliana Contreras",
      sucursal: "Cortaderas",
      whatsapp: "2664541080",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/lilianacontreras.png",
      correo: "lilianacontrerasinmobiliaria@gmail.com",
    },
    {
      nombre: "Romina Oyarzun",
      sucursal: "Merlo",
      whatsapp: "3544570339",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/rominaoyarzun.png",
      correo: "rominaoyarzun8@gmail.com",
    },
    {
      nombre: "Juana Brunner",
      sucursal: "Merlo",
      whatsapp: "2665008003",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/juanabrunner.png",
      correo: "brunnerjuanaangelica@gmail.com",
    },
  ];